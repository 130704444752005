import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SwitchesAndPointMachines from "../../screens/switches_and_point_machines/SwitchesAndPointMachines";
import {styled, useTheme} from "@mui/material/styles";
import {TurnoutsSchema} from "../../net/repository";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface IHomeTab {
    onSeeRecord?: (turnout: TurnoutsSchema) => void
}

export default function HomeTab({onSeeRecord}: IHomeTab) {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    interface StyledTabProps {
        label: string;
    }

    const StyledTab = styled((props: StyledTabProps) => (
        <Tab disableRipple {...props} />
    ))(({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 1)',
        '&.Mui-selected': {
            color: theme.palette.action.active,
            borderBottom: 0,
            borderColor: '#fff'
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }));

    return (
        <Box sx={{ mt:'5vh', backgroundColor: '#45535e', width: '100%', pt: '3vh'}}>
            <Box sx={{display: 'flex', justifyContent: 'center', color: 'white'}}>
                <Tabs TabIndicatorProps={{
                    style:{
                        borderBottom: 0
                    }
                }} sx={{border: 2, borderRadius: 1, }} value={value} onChange={handleChange} aria-label="basic tabs example">
                    <StyledTab sx={{borderRight: 1, fontWeight: '700'}}
                         label="Switches" {...a11yProps(0)} />
                    <StyledTab sx={{ fontWeight: '700'}}
                         label="Point Machines" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <SwitchesAndPointMachines onSeeRecord={onSeeRecord} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Typography component={'span'} sx={{color: theme.palette.action.active}} variant='h6'>Coming Soon</Typography>
            </CustomTabPanel>
        </Box>
    );
}