import React, {useEffect, useState} from "react";
import {DateCalendar, DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Box from "@mui/material/Box";
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Search} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {PointMachineEventSchema, TurnoutsSchema} from "../../../net/repository";
import {fetchPointMachineEvents} from "../../../net/repo";
import {ChartDialog} from "../../../components/dialogs/ChartDialog";
import IconButton from "@mui/material/IconButton";

interface StatisticsProps {
    turnout: TurnoutsSchema | null
}

export default function Statistics({turnout}: StatisticsProps) {
    const [selectedPointMachine, setPointMachine] = React.useState('0');
    const [maxCount, setMaxCount] = React.useState(20);
    const [isLoading, setLoadingState] = React.useState(true);
    const [selectedStartDate, onDateStartSelected] = useState(null)
    const [selectedEndDate, onDateEndSelected] = useState(null)
    const [pointMachineEvents, setPointMachineEvents] = React.useState<Array<PointMachineEventSchema>>([]);
    const theme = useTheme();

    const handleChange = (event: SelectChangeEvent) => {
        setPointMachine(event.target.value);
    };

    useEffect(() => {
        onFetchData()
    }, [selectedPointMachine, maxCount, turnout]);


    const onFetchData = () => {
        const index = parseInt(selectedPointMachine);
        if (turnout && Array.isArray(turnout.pointMachines)) {
            if (turnout.pointMachines.length > 0) {
                const pointMachine = turnout.pointMachines[index];

                if(!maxCount) return;

                console.log(selectedStartDate)
                console.log(selectedEndDate)

                const request = fetchPointMachineEvents({
                    machineId: pointMachine.id as string,
                    page: 1,
                    pageSize: maxCount,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate
                });

                request.then((response) => {
                    setPointMachineEvents(response.data);
                    setLoadingState(false);
                }).catch((reason) => {
                    setLoadingState(false)
                })
                return
            }
        }
    }

    const onFilterByDate = () => {
        setPointMachineEvents([]);
        setLoadingState(true);
        onFetchData()
    }

    const onUpdateMaxCount = (e) => {
        setMaxCount(e.target.value);
    }

    const onUpdateSelectedStartDate = (value: any) => {
        if (!value) {
            onDateStartSelected(null);
            return;
        }

        const date = new Date(value.$d);
        const isoDate = date.toISOString();
        onDateStartSelected(isoDate);
    }

    const onUpdateSelectedEndDate = (value: any) => {
        if (!value) {
            onDateEndSelected(null);
            return;
        }

        const date = new Date(value.$d);
        const isoDate = date.toISOString();
        onDateEndSelected(isoDate);
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{
                    height: 'auto',
                    mt: '5vh',
                    pt: 2,
                    pb: 2,
                    flexGrow: 1,
                    backgroundColor: '#45545E',
                }}>
                    <Grid container sx={{p: '20px'}}>
                        <Grid sx={{}}>
                            <Typography sx={{color: 'white', mt: 2, fontWeight: 700,}} variant='subtitle1'>Select Point
                                Machines:</Typography>
                        </Grid>
                        <Grid sx={{minWidth: 120, ml: '20px'}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Point Machines</InputLabel>
                                <Select
                                    labelId="point-machine-select"
                                    id="pm-select"
                                    value={selectedPointMachine}
                                    label="Select Point Machines"
                                    onChange={handleChange}
                                >
                                    {turnout && turnout.pointMachines && turnout.pointMachines.map((pointMachine, index) =>
                                        <MenuItem
                                            key={pointMachine.id}
                                            value={index}>{pointMachine.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/*<Grid container direction="row">*/}
                    {/*    <Grid direction="row" sx={{minWidth: '100%', backgroundColor: 'red', ml: '20px'}}>*/}
                    {/*            <Typography sx={{color: 'white', mt: 2, fontWeight: 700,}} variant='subtitle1'>Select Start Date*/}
                    {/*                Machines:</Typography>*/}
                    {/*            <DatePicker onChange={onUpdateSelectedStartDate} sx={{border: 0, ml: 2}}/>*/}
                    {/*            <Typography sx={{color: 'white', mt: 2, fontWeight: 700,}} variant='subtitle1'>Select End Date*/}
                    {/*                Machines:</Typography>*/}
                    {/*            <DatePicker onChange={onUpdateSelectedEndDate} sx={{border: 0, ml: 2}}/>*/}
                    {/*            /!*<DateCalendar />*!/*/}
                    {/*            /!*<DatePicker onChange={onUpdateSelectedDate} sx={{border: 0, ml: 2}}/>*!/*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </Box>


                <Box sx={{
                    height: 'auto',
                    mt: '1vh',
                    flexGrow: 1,
                    backgroundColor: '#45545E',
                }}>
                    <Grid container sx={{ width: '100%', p: '20px'}}>
                        <Grid display="flex" sx={{ pt: 2, pr: 2 }} xs={12} lg={2} md={1} xl={2} item>
                            <Typography fontSize={'16px'} fontWeight='normal' color={theme.palette.action.active} variant='h4'>Filter alerts by dates</Typography>
                        </Grid>
                        <Grid display="flex" lg={1} item />
                        <Grid display="flex" item sx={{ width: 'auto', marginLeft: 1,}} container md={11}  lg={8} xl={8} xs={12}>
                            <Grid item ></Grid>
                            <Grid item sx={{ marginRight: 1}}>
                                <DatePicker  onChange={onUpdateSelectedStartDate} sx={{ border: 0}} />
                            </Grid>
                            <Grid sx={{ mt: theme.spacing(2), }} item> <Typography color='#fff'> - </Typography></Grid>
                            <Grid sx={{ marginLeft: 1}} item>
                                <DatePicker onChange={onUpdateSelectedEndDate} />
                            </Grid>
                            <Grid item>
                                <IconButton onClick={onFilterByDate} aria-label="search">
                                    <Search fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </LocalizationProvider>


            <Box sx={{height: 'auto', mt: '10vh'}}>
                <Grid sx={{mb: '20px'}} container justifyContent='space-between'>
                    <Grid sx={{mt: 2,}}>
                        <Typography sx={{fontWeight: 700}} color={theme.palette.action.active}
                                    variant='subtitle1'>Latest</Typography>
                    </Grid>
                    <Grid>
                        <TextField id="max-count" value={maxCount} onChange={onUpdateMaxCount} label="Max Count"
                                   variant="outlined"/>
                    </Grid>
                </Grid>
                {isLoading ? <CircularProgress/> : <TableContainer component={Paper} style={{maxHeight: '50vh',}}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight: 650}} align="center">Date</TableCell>
                                <TableCell sx={{fontWeight: 650}} align="center">Pos</TableCell>
                                {/*<TableCell sx={{fontWeight: 650}} align="center">Temp</TableCell>*/}
                                <TableCell sx={{fontWeight: 650}} align="center">OTime</TableCell>
                                {/*<TableCell sx={{fontWeight: 650}} align="center">Time Stop</TableCell>*/}

                                <TableCell sx={{fontWeight: 650}} align="center">U</TableCell>
                                <TableCell sx={{fontWeight: 650}} align="center">O</TableCell>
                                <TableCell sx={{fontWeight: 650}} align="center">L</TableCell>
                                <TableCell sx={{fontWeight: 650}} align="center"> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pointMachineEvents.length < 1 ?
                                <TableRow><TableCell colSpan={8}><Typography align={"center"}>No Events for this Point
                                    Machine</Typography></TableCell></TableRow>
                                :
                                pointMachineEvents.map((pointMachineEvent: PointMachineEventSchema) => (
                                    <TableRowItem
                                        key={pointMachineEvent.id}
                                        pointMachineEvent={pointMachineEvent}/>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Box>
        </>
    );
}

interface ITableRowItem {
    pointMachineEvent: PointMachineEventSchema;
}

function TableRowItem({pointMachineEvent}: ITableRowItem) {
    const [showGraph, setShowGraphState] = React.useState(false);


    const createdAt = new Date(pointMachineEvent.createdAt);
    const dateTimeString = `${createdAt.toDateString()} ${createdAt.toTimeString().substring(0, 12)}`
    const startTime = Date.parse(pointMachineEvent.timestampStart);
    const stopTime = Date.parse(pointMachineEvent.timestampStop);

    const oTimeInSecs = (stopTime - startTime) / 1000;
    const direction = pointMachineEvent.turnoutPosition === 'divergent' ? "curved" : pointMachineEvent.turnoutPosition;
    const stripUndefinedPosition = direction === "undefined" ? "N/A" : direction;

    return (
        <TableRow
            key={pointMachineEvent.id}
            // sx={{'&:last-child td, &:last-child th': {border: 0}}}
        >
            <TableCell sx={{fontWeight: 650}} size='small'
                       align="center">{dateTimeString}</TableCell>
            <TableCell sx={{fontWeight: 650}}
                       align="center">{stripUndefinedPosition}</TableCell>
            <TableCell sx={{fontWeight: 650}} align="center">{oTimeInSecs} secs</TableCell>
            {/*<TableCell sx={{fontWeight: 650}} align="center">{pointMachineEvent.timestampStop}</TableCell>*/}
            {/*<TableCell sx={{fontWeight: 650}} align="center">{pointMachineEvent.temp}</TableCell>*/}
            <TableCell size='small' style={{width: 30}} align="center">
                <Box alignContent='space-evenly'>
                    <Box sx={{
                        height: 20,
                        width: 30,
                        border: 1,
                        justifyContent: 'center',
                        alignSelf: 'center',
                        borderColor: 'white',
                        backgroundColor: 'green'
                    }}>
                    </Box>
                </Box>
            </TableCell>
            <TableCell style={{width: 30}} align="center">
                <Box sx={{
                    height: 20,
                    width: 30,
                    border: 1,
                    m: 0,
                    borderColor: 'white',
                    backgroundColor: 'green'
                }}>
                </Box>
            </TableCell>
            <TableCell style={{width: 30}} align="center">
                <Box sx={{
                    height: 20,
                    width: 30,
                    border: 1,
                    borderColor: 'white',
                    backgroundColor: 'green'
                }}>
                </Box>
            </TableCell>

            <TableCell size='small' align="left">
                <Button onClick={() => setShowGraphState(true)}>
                    <Search sx={{color: 'white'}} fontSize='large'></Search>
                </Button>
                {showGraph &&
                    <ChartDialog open={showGraph} eventId={pointMachineEvent.id as string} onClose={setShowGraphState}
                                 selectedValue={pointMachineEvent}/>}
            </TableCell>
        </TableRow>
    )
}