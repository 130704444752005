/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AuthenticationService } from './services/AuthenticationService';
import { CachesService } from './services/CachesService';
import { DevicesService } from './services/DevicesService';
import { DocumentationService } from './services/DocumentationService';
import { TrainsService } from './services/TrainsService';
import { TurnoutsService } from './services/TurnoutsService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class HttpClient {
    public readonly authentication: AuthenticationService;
    public readonly caches: CachesService;
    public readonly devices: DevicesService;
    public readonly documentation: DocumentationService;
    public readonly trains: TrainsService;
    public readonly turnouts: TurnoutsService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '/api/v1',
            VERSION: config?.VERSION ?? 'local',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.authentication = new AuthenticationService(this.request);
        this.caches = new CachesService(this.request);
        this.devices = new DevicesService(this.request);
        this.documentation = new DocumentationService(this.request);
        this.trains = new TrainsService(this.request);
        this.turnouts = new TurnoutsService(this.request);
    }
}

