import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {fetchSwitches} from "../../net/repo";
import {TurnoutsSchema} from "../../net/repository";


interface ISwitchesAndPointMachines {
    onSeeRecord?: (turnout: TurnoutsSchema) => void
}

export default function SwitchesAndPointMachines({onSeeRecord}: ISwitchesAndPointMachines) {

    const theme = useTheme();
    const [switches, setSwitchData] = useState<Array<TurnoutsSchema>>([]);
    const [isLoading, setLoadingState] = React.useState(true);

    useEffect(() => {
        const request = fetchSwitches({page: 1, pageSize: 10});
        request.then((response) => {
            setSwitchData(response.data)
            setLoadingState(false)
            // console.log(response);
        }).catch((reason) => {
            setLoadingState(false)
            // console.log(reason);
        });
    }, []);

    return (
        <>
            <Box>
                <Grid container spacing={2} sx={{mt: '10px', ml: '30px'}}>
                    <Grid>
                        <Box sx={{mt: '10px', mr: '10px'}}>
                            <Typography fontWeight='bold' color='#fff' variant='h6'>Country:</Typography>
                        </Box>
                    </Grid>
                    <Grid>
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={0}
                                label="Age"
                                sx={{
                                    '& legend': {display: 'none'},
                                    '& fieldset': {top: 0},
                                    width: '15vw',
                                    fontWeight: '700',
                                    height: '40px',
                                    mt: '10px'
                                }}
                                onChange={() => {
                                }}
                            >
                                <MenuItem sx={{fontWeight: '700'}} value={0}>Sweden</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{mt: '10px', ml: '30px'}}>
                    <Grid>
                        <Box sx={{mt: '10px', mr: '31px'}}>
                            <Typography color='#fff' fontWeight='bold' variant='h6'>Client:</Typography>
                        </Box>
                    </Grid>
                    <Grid>
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={0}
                                label="Age"

                                sx={{
                                    '& legend': {display: 'none'},
                                    '& fieldset': {top: 0},
                                    width: '15vw',
                                    height: '40px',
                                    mt: '10px'
                                }}
                                onChange={() => {
                                }}
                            >
                                <MenuItem sx={{fontWeight: '700'}} value={0}>Jernhusen</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box sx={{mt: '5vh'}}>
                    {isLoading ? <CircularProgress style={{
                        display: 'flex',
                        alignItems: 'center',
                        // marginTop: '40vh',
                        justifyContent: 'center'
                    }} /> : <TableContainer style={{height: '60vh',}}>
                        <Table sx={{}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{}} align="left">Switch Name</TableCell>
                                    <TableCell align="left">Description</TableCell>
                                    {/*<TableCell align="left">Created</TableCell>*/}
                                    {/*<TableCell align="left">Last Update</TableCell>*/}
                                    <TableCell align="left">No. of Point Machines</TableCell>
                                    <TableCell align="left"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {switches.map((row: TurnoutsSchema) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.description}</TableCell>
                                        {/*<TableCell align="left">{row.createdAt}</TableCell>*/}
                                        {/*<TableCell align="left">{row.updatedAt}</TableCell>*/}
                                        <TableCell align="left">{row.pointMachines?.length}</TableCell>
                                        <TableCell align="left">
                                            <Button sx={{
                                                color: theme.palette.action.active,
                                                borderColor: theme.palette.action.active,
                                                fontSize: 14,
                                                width: '150px'
                                            }}
                                                    onClick={() => onSeeRecord ? onSeeRecord(row) : console.log("Clicked Button")}
                                                    variant="outlined"> See records</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Box>
            </Box>
        </>

    );
}