import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Statistics from "../statistics/Statistics";
import {styled, useTheme} from "@mui/material/styles";
import {TurnoutsSchema} from "../../../net/repository";
import Records from "../records/Records";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    sx?: any;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function SwitchDetailsTabPanel(props: TabPanelProps) {
    const {children, value, sx, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={sx}>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface ISwitchDetails {
    setWideScreenState: (state: boolean) => void;
    turnout: TurnoutsSchema | null;
}

export default function SwitchDetails(props: ISwitchDetails) {

    const { setWideScreenState } = props;

    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        if(newValue === 1){
            setWideScreenState(true);
            return;
        }
        setWideScreenState(false);
    };

    interface StyledTabProps {
        label: string;
    }

    const StyledTab = styled((props: StyledTabProps) => (
        <Tab disableRipple {...props} />
    ))(({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 1)',
        '&.Mui-selected': {
            color: theme.palette.action.active,
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }));

    const rows2 = [
        {
            date: '2021-06-02 10:28:42',
            pos: 'R',
            temp: 29.5,
            ta1: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta2: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta3: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta4: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta5: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
        },
        {
            date: '2021-06-02 10:28:42',
            pos: 'R',
            temp: 29.5,
            ta1: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta2: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta3: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta4: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta5: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
        },
        {
            date: '2021-06-02 10:28:42',
            pos: 'R',
            temp: 29.5,
            ta1: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta2: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta3: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta4: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
            ta5: {
                o: 3,
                u: 3,
                l: 4,
                oTime: 4.16
            },
        },
    ]

    return (
        <Box>
            <Box sx={{width: '100%', ml: 6,  pt: '3vh'}}>
                <Box sx={{mb: '10px'}}>
                    <Typography variant='subtitle1' color={theme.palette.action.active}
                                fontWeight='bold'>Goteborg</Typography>
                    <Typography variant='h5' sx={{fontWeight: '700', color: 'white'}}>{props.turnout?.name}</Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'start', color: 'white'}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                            children: <div style={{
                                height: 10,
                                width: 26,
                                backgroundColor: 'white',
                                display: "flex",
                                justifyContent: 'center',
                                clipPath: 'polygon(0 0, 100% 0, 50% 100%)'
                            }}>

                                <div style={{
                                    height: 8,
                                    width: 22,
                                    // marginTop: 1,
                                    backgroundColor: '#3A4750',
                                    clipPath: 'polygon(0 0, 100% 0, 50% 100%)'
                                }}/>

                            </div>,
                            style: {
                                top: theme.spacing(5.85),
                                height: 10,
                                width: 26,
                                marginLeft: theme.spacing(10.5),
                                backgroundColor: '#3A4750',
                                borderTop: 2,
                                borderColor: '#3A4750',
                                clipPath: 'polygon(0 0, 100% 0, 50% 100%)',
                            },
                        }}
                        aria-label="basic tabs example">
                        <StyledTab
                            sx={{
                                m: 0,
                                boxShadow: '0px',
                                pl: theme.spacing(8),
                                pr: theme.spacing(8),
                                marginBottom: '10px',
                                borderBottom: 1,
                                borderColor: 'white'
                            }}
                            label="Statistics" {...a11yProps(0)} />
                        <StyledTab
                            sx={{
                                m: 0,
                                pl: theme.spacing(8),
                                pr: theme.spacing(8),
                                marginBottom: '10px',
                                borderBottom: 1,
                                borderColor: 'white'
                            }}
                            label="Records" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <SwitchDetailsTabPanel value={value} index={0}>
                    <Statistics turnout={props.turnout} />
                </SwitchDetailsTabPanel>
                <SwitchDetailsTabPanel value={value} index={1}>
                    <Typography variant={"h5"} m={2} color={"white"}>New feature coming Soon</Typography>
                    {/*<Records pointMachines={props.turnout.pointMachines} tableData={rows2}/>*/}
                </SwitchDetailsTabPanel>
            </Box>
        </Box>
    );
}