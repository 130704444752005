/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertAttributeEnum } from '../models/AlertAttributeEnum';
import type { AlertLevelInput } from '../models/AlertLevelInput';
import type { AssetOrderBy } from '../models/AssetOrderBy';
import type { ListTrainField } from '../models/ListTrainField';
import type { ListTrainResponse } from '../models/ListTrainResponse';
import type { ListTrainWheelSetAssetsResponse } from '../models/ListTrainWheelSetAssetsResponse';
import type { MeasurementDateRange } from '../models/MeasurementDateRange';
import type { TrainDetail } from '../models/TrainDetail';
import type { TrainOrderBy } from '../models/TrainOrderBy';
import type { TrendGroupEnum } from '../models/TrendGroupEnum';
import type { WheelAlertResponse } from '../models/WheelAlertResponse';
import type { WheelSetAssetForecastParametersResponse } from '../models/WheelSetAssetForecastParametersResponse';
import type { WheelSetAssetForecastResponse } from '../models/WheelSetAssetForecastResponse';
import type { WheelSideChoice } from '../models/WheelSideChoice';
import type { WheelTimeSeriesResponse } from '../models/WheelTimeSeriesResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TrainsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List Trains
     * Returns a list of trains in specified order
     * @param extraFields Include extra fields in response
     * @param levels Filter list to only include trains which these specific alerts, alerts are only checked on chosen attributes, an empty list disables the filter
     * @param datetimeFrom Only include alerts after or on this date-time
     * @param datetimeTo Only include alerts before this date-time
     * @param attributes Limits which alerts that are included in the error and warning counts to only those matching these attributes, an empty list disables the filter
     * @param page
     * @param pageSize
     * @param orderBy
     * @returns ListTrainResponse Successful Response
     * @throws ApiError
     */
    public listTrainsTrainsGet(
        extraFields?: (Array<ListTrainField> | null),
        levels?: (Array<AlertLevelInput> | null),
        datetimeFrom?: (string | null),
        datetimeTo?: (string | null),
        attributes?: (Array<AlertAttributeEnum> | null),
        page: number = 1,
        pageSize: number = 50,
        orderBy?: Array<TrainOrderBy>,
    ): CancelablePromise<ListTrainResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/trains/',
            query: {
                'extra_fields': extraFields,
                'levels': levels,
                'datetime_from': datetimeFrom,
                'datetime_to': datetimeTo,
                'attributes': attributes,
                'page': page,
                'page_size': pageSize,
                'order_by': orderBy,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Train Measurement Dateranges
     * Returns a list of date ranges which contains measurements for trains
     * @param datetimeFrom Only consider measurements after or on this date-time
     * @param datetimeTo Only consider measurements before this date-time
     * @returns MeasurementDateRange Successful Response
     * @throws ApiError
     */
    public listTrainMeasurementDateRangesTrainsAvailableDateRangesGet(
        datetimeFrom?: (string | null),
        datetimeTo?: (string | null),
    ): CancelablePromise<Array<MeasurementDateRange>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/trains/available_date_ranges',
            query: {
                'datetime_from': datetimeFrom,
                'datetime_to': datetimeTo,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Train Details
     * Returns detailed information on a train
     * @param trainId
     * @param withFormation Controls whether details on the train formation (vehicles, bogies, wheels etc.) are included, True to include, False to skip
     * @param withAssets Controls whether details on the associated assets (vehicles, bogies, wheels etc.) are included, True to include, False to skip
     * @returns TrainDetail Successful Response
     * @throws ApiError
     */
    public getTrainDetailsTrainsTrainIdGet(
        trainId: string,
        withFormation: boolean = false,
        withAssets: boolean = false,
    ): CancelablePromise<TrainDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/trains/{train_id}',
            path: {
                'train_id': trainId,
            },
            query: {
                'with_formation': withFormation,
                'with_assets': withAssets,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Alerts
     * Returns a list of alerts for a given train
     * @param trainId UUID of train that alerts are fetched from
     * @param levels Filter list to only include alerts with these levels, an empty list disables the filter
     * @param datetimeFrom Only include alerts after or on this date-time
     * @param datetimeTo Only include alerts before this date-time
     * @param attributes Filter list to only include alerts for these attributes, an empty list disables the filter
     * @returns WheelAlertResponse Successful Response
     * @throws ApiError
     */
    public listAlertsWheelsAlertsGet(
        trainId: string,
        levels?: Array<AlertLevelInput>,
        datetimeFrom?: (string | null),
        datetimeTo?: (string | null),
        attributes?: (Array<AlertAttributeEnum> | null),
    ): CancelablePromise<WheelAlertResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/wheels/alerts',
            query: {
                'train_id': trainId,
                'levels': levels,
                'datetime_from': datetimeFrom,
                'datetime_to': datetimeTo,
                'attributes': attributes,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Fetch Wheel Time Series
     * Returns a set of time series data sets, one per wheel/type
     * @param trainId UUID of train that metrics are fetched from
     * @param datetimeFrom Only include metrics after or on this date-time
     * @param datetimeTo Only include metrics before this date-time
     * @param wheelSetIds Limit results to these wheel-set UUIDs
     * @param wheelIds Limit results to these wheel UUIDs
     * @param wheelSetPositions Limit results to these wheel-set positions
     * @param wheelNumbers Limit results to these wheel positions
     * @param attributes Filter list to only include metrics for these attributes, an empty list disables the filter
     * @param withAssets If true then historical information on installed assets is included
     * @param withHistory If true then it includes measurements, assets and trends for all assets that have been installed in each position, false returns just the data from the latest maintenance interval
     * @param withAssetDistances If true then travel distances of each asset is included
     * @param withMaintenanceDistances If true then travel distances of each asset, relative to last maintenance, is included
     * @param trends Include specific trends
     * @param trendForecastDistance Sets the driving distance (in meters) in the future to use for trend forecast
     * @returns WheelTimeSeriesResponse Successful Response
     * @throws ApiError
     */
    public fetchWheelTimeSeriesWheelsTimeSeriesGet(
        trainId: string,
        datetimeFrom?: (string | null),
        datetimeTo?: (string | null),
        wheelSetIds?: Array<string>,
        wheelIds?: Array<string>,
        wheelSetPositions?: Array<number>,
        wheelNumbers?: Array<number>,
        attributes?: (Array<AlertAttributeEnum> | null),
        withAssets: boolean = false,
        withHistory: boolean = false,
        withAssetDistances: boolean = true,
        withMaintenanceDistances: boolean = false,
        trends?: (Array<TrendGroupEnum> | null),
        trendForecastDistance: number = 100000000,
    ): CancelablePromise<WheelTimeSeriesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/wheels/time_series',
            query: {
                'train_id': trainId,
                'datetime_from': datetimeFrom,
                'datetime_to': datetimeTo,
                'wheel_set_ids': wheelSetIds,
                'wheel_ids': wheelIds,
                'wheel_set_positions': wheelSetPositions,
                'wheel_numbers': wheelNumbers,
                'attributes': attributes,
                'with_assets': withAssets,
                'with_history': withHistory,
                'with_asset_distances': withAssetDistances,
                'with_maintenance_distances': withMaintenanceDistances,
                'trends': trends,
                'trend_forecast_distance': trendForecastDistance,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Fetch Wheel Set Assets
     * Returns a list of wheel-set assets
     * @param wheelSetIds Limit results to these wheel-set asset UUIDs
     * @param withHistory If true then historical installation date is also included
     * @param page
     * @param pageSize
     * @param orderBy
     * @returns ListTrainWheelSetAssetsResponse Successful Response
     * @throws ApiError
     */
    public fetchWheelSetAssetsWheelsAssetsGet(
        wheelSetIds?: Array<string>,
        withHistory: boolean = false,
        page: number = 1,
        pageSize: number = 50,
        orderBy?: Array<AssetOrderBy>,
    ): CancelablePromise<ListTrainWheelSetAssetsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/wheels/assets',
            query: {
                'wheel_set_ids': wheelSetIds,
                'with_history': withHistory,
                'page': page,
                'page_size': pageSize,
                'order_by': orderBy,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Fetch Parameters For Wheel Set Asset Lifetime
     * Returns the expected lifetime for a chosen wheel set asset
     * @param assetId UUID of wheel-set asset to analyze
     * @param wheelSide Which side of wheel-set to analyze, left or right
     * @returns WheelSetAssetForecastParametersResponse Successful Response
     * @throws ApiError
     */
    public fetchParametersForWheelSetAssetLifetimeWheelsAssetsAssetIdLifetimeParametersGet(
        assetId: string,
        wheelSide: WheelSideChoice,
    ): CancelablePromise<WheelSetAssetForecastParametersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/wheels/assets/{asset_id}/lifetime_parameters',
            path: {
                'asset_id': assetId,
            },
            query: {
                'wheel_side': wheelSide,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Fetch Wheel Set Asset Remaining Lifetime
     * Returns the expected lifetime for a chosen wheel set asset
     * @param assetId UUID of wheel-set asset to analyze
     * @param wheelSide Which side of wheel-set to analyze, left or right
     * @param wearRateWheelDiameter Wear rate of wheel diameter, in mm/km
     * @param wearRateFlangeThickness Wear rate of flange thickness, in mm/km
     * @param meanDrivingDistance Mean driving distance for wheel, in km
     * @param stdDrivingDistance Standard driving distance for wheel, in km
     * @param resolution Resolution of distance values, in km
     * @returns WheelSetAssetForecastResponse Successful Response
     * @throws ApiError
     */
    public fetchWheelSetAssetRemainingLifetimeWheelsAssetsAssetIdLifetimePost(
        assetId: string,
        wheelSide: WheelSideChoice,
        wearRateWheelDiameter: number = 1.7,
        wearRateFlangeThickness: number = 0.5,
        meanDrivingDistance: number = 180000,
        stdDrivingDistance: number = 50000,
        resolution: number = 25000,
    ): CancelablePromise<WheelSetAssetForecastResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/wheels/assets/{asset_id}/lifetime',
            path: {
                'asset_id': assetId,
            },
            query: {
                'wheel_side': wheelSide,
                'wear_rate_wheel_diameter': wearRateWheelDiameter,
                'wear_rate_flange_thickness': wearRateFlangeThickness,
                'mean_driving_distance': meanDrivingDistance,
                'std_driving_distance': stdDrivingDistance,
                'resolution': resolution,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
